import { merchantSignupInterface, adminLoginInterface, forgetPasswordInterface, verifyAnswerInterface, verifyForgotPasswordOTPInterface, merchantProfileInterface } from "./interface/auth.interface"
import supportInstance from "./instances/support.instance"
import { removeUser } from "./token.service";
import axios from 'axios'



// _____________________________Activate Wallet __________________________________

// async function activateMerchantWallet(data: any) {
//   let response = await supportInstance.post(`/merchant/activate-wallet`, data);
//   try {
//     return response.data
//   } catch (err) {
//     return err
//   }
// }


// _____________________________Get Privacy policy Data __________________________________

// async function getPrivacyPoliciy() {
//   let response = await supportInstance.get(`/merchant/banks`);
//   try {
//     return response.data
//   } catch (err) {
//     return err
//   }
// }

// _____________________________Get FAQs Data __________________________________

async function getFAQs() {
  let response = await supportInstance.get(`/admin/faq`);
  try {
    return response.data
  } catch (err) {
    return err
  }
}


// _____________________________Get Privacy and T&C data __________________________________

async function getPrivacyAndTnCData(data: string) {
  let response = await supportInstance.get(`/admin/legal/${data}`);
  try {
    return response.data
  } catch (err) {
    return err
  }
}


// _____________________________Add Privacy and Terms__________________________________

async function addPrivacyAndTnCData(data: any, fileType: any) {
  let response = await supportInstance.post(`/admin/legal/${fileType}`, data);
  try {
    return response.data
  } catch (err) {
    return err
  }
}


// _____________________________Add FAQ__________________________________

async function AddFAQ(data: any) {
  let response = await supportInstance.post(`/admin/faq`, data);
  try {
    return response.data
  } catch (err) {
    return err
  }
}


// _____________________________Delete FAQ__________________________________

async function deleteFAQ(id: any) {
  let response = await supportInstance.delete(`/admin/faq/${id}`);
  try {
    return response.data
  } catch (err) {
    return err
  }
}


// _____________________________Edit FAQ__________________________________

async function editFAQ(data: any, id: any) {
  let response = await supportInstance.put(`/admin/faq/${id}`, data);
  try {
    return response.data
  } catch (err) {
    return err
  }
}




// _____________________________ Delete Bank __________________________________

// async function deleteMerchantBank(data: any) {
//   let response = await supportInstance.delete(`/merchant/bank`, { data });
//   try {
//     return response.data
//   } catch (err) {
//     return err
//   }
// }

// ______________________________________get all ticket___________________________________________


async function getAllTickets(data: { skip: number, limit: number }) {
  const { skip, limit } = data
  let response = await supportInstance.get(`/admin/list/tickets?skip=${skip}&limit=${limit}`);
  try {
    return response.data;
  } catch (err) {
    return err;
  }
}



const supportService = {
  // activateMerchantWallet,
  getFAQs,
  AddFAQ,
  getPrivacyAndTnCData,
  addPrivacyAndTnCData,
  deleteFAQ,
  editFAQ,
  getAllTickets,
  // getMerchantBank,
  // deleteMerchantBank,
};

export default supportService;
