import walletService from "../../../services/walletService"
import { createAsyncThunk } from "@reduxjs/toolkit"

interface AsyncFunctionParams {
  rejectWithValue: (value: any) => void;
}



// --------------------------------Get Merchant Transaction---------------------------------

export const getMerchantTransactions = createAsyncThunk(
  'transactions/getMerchantTransactions',
  async (data: any, params: AsyncFunctionParams) => {
    try {
      const response: any = await walletService.getMerchantTransaction(data);
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return params.rejectWithValue(error.response.data.message)
      } else {
        return params.rejectWithValue(error.message)
      }
    }
  }
)


// --------------------------------Get Admin Transaction---------------------------------

export const getTransactions = createAsyncThunk(
  'transactions/getTransactions',
  async (data: any, params: AsyncFunctionParams) => {
    try {
      const response: any = await walletService.getTransaction(data);
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return params.rejectWithValue(error.response.data.message)
      } else {
        return params.rejectWithValue(error.message)
      }
    }
  }
)