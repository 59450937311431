import React, { useState, useEffect } from 'react'
import Header from '../../../component/Admin/AdminHeader';
import LeftMenu from '../../../component/Admin/AdminLeftMenu';
import { NavLink } from "react-router-dom";
import supportService from '../../../services/supportService';
import { AppDispatch } from "../../../redux/store"
import { useDispatch, useSelector } from 'react-redux';
import { getFAQs } from '../../../redux/features/support/supportAction';
import Skeleton from 'react-loading-skeleton';

const HelpSupport = () => {
    const [isActive, setIsActive] = useState(false);
    const dispatch: AppDispatch = useDispatch()
    const faqData = useSelector((state: any) => state.faqs)

    useEffect(() => {
        dispatch(getFAQs())
    }, [])

    return (
        <>
            <div className="dbRight">
                <div className="mainHd">Help & Support</div>
                <div className="flexBox helpSupportBox">
                    <div>
                        <div className="supportBox">
                            <ul className="supportLinks flexBox">
                                {faqData?.loading ?
                                    [...Array(10)].map((data, i) => {
                                        return (
                                            <>
                                                <li key={i} ><Skeleton style={{ margin: ".3rem 0", borderRadius: "14px", width: "100%" }} height={"3rem"} /></li>
                                            </>

                                        )
                                    })
                                    : ""}

                                {faqData?.data?.titles?.map((data: any) => {
                                    return (
                                        <li><NavLink to={`/admin/help-support/detail/${data}`}>{data}</NavLink></li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    {/* <div className="right">
                    <span>Still Need Help?</span>
                    <a>Request a Call Back</a>
                </div> */}
                </div>
            </div>
        </>

    )
}

export default HelpSupport