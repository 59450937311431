import React, { useState, useEffect } from "react";
import LeftMenu from "../../../component/Merchant/MerchantLeftMenu";
import Receipt from "../../../component/Receipt";
import DatePicker from "react-datepicker";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { getMerchantTransactions } from '../../../redux/features/transaction/transactionAction';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from "../../../redux/store"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { convertUTCDateTimetoLocal } from "../../../utils/timeConverter"
import ReactPaginate from 'react-paginate';
import { useDebounce } from 'use-debounce';
import walletService from "../../../services/walletService";


const initialParamState: any = {
  page: 1,
  search: "",
  page_size: 10,
  status: ""
};


const Transactions = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isActive, setIsActive] = useState(false);
  const toggleMenu = () => {
    setIsActive(!isActive);
  };
  const [singleDate, setSingleDate] = useState<Date | null>(new Date());

  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  // const [isActive, setIsActive] = useState(false);
  // const toggleMenu = () => {
  //     setIsActive(!isActive);
  // };
  // const [singleDate, setSingleDate] = useState<Date | null>(new Date());
  const dispatch: AppDispatch = useDispatch()
  const transactionData = useSelector((state: any) => state.transaction)
  const [perPage, setPerPage] = useState(10)
  const [paramState, setParamState] = useState(initialParamState);
  const [viewReceiptData, setViewReceiptData] = useState();
  const [requestData, setRequestData] = useState({ received_requests: [], received_requests_size: 0, sent_requests: [], sent_requests_size: 0 });
  const [receivedData, setReceivedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState({ id: "", state: false, action: "" });
  const { page, page_size, search, status } = paramState;
  const [value] = useDebounce(search, 300);



  useEffect(() => {
    setIsLoading(true)
    let params = { page, page_size };
    walletService.getMerchantSendRequest(params).then((res: any) => {
      if (!res?.error) {
        const { received_requests, received_requests_size, sent_requests, sent_requests_size } = res
        setRequestData({ received_requests, received_requests_size, sent_requests, sent_requests_size })
      }
      setIsLoading(false)
    }).catch((err) => {
      setIsLoading(false)

    })

  }, [reload])

  const handlePageChange = async ({ selected }: any) => {
    let selectedPage = selected + 1;
    setPerPage(10 * Number(selectedPage));
    let params: any = { page: selectedPage, page_size };
    await dispatch(getMerchantTransactions(params));
    setParamState({
      page: selectedPage,
      page_size,
    })
  };

  const handleRequest = (data: any) => {
    setIsActionLoading({ id: data.id, state: true, action: data.action })
    const params = {
      id: data.id,
      action: data.action
    }
    walletService.merchantRequestMoneyAction(params).then((res: any) => {
      setIsActionLoading({ id: data.id, state: false, action: data.action })

      if (!res?.error) {
        setReload(!reload)
      }
    }).catch((err) => {
      setIsActionLoading({ id: data.id, state: false, action: data.action })
    })
  }


  return (
    <>
      <div className="dbRight">
        <div className="mainHd">Requests</div>
        <div className="whtBox">
          <div className="tabSection">
            <Tabs
              defaultActiveKey="sent"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="sent" title="Sent">
                <div className="flexBox itemCenter justifyContentBetween">
                  <div className="totalNumber">Total: {requestData?.sent_requests_size}</div>
                  <ul className="flexBox filter transactionsFilter">
                    {/* <li>
                      <div className="filterSearch pr">
                        <input
                          type="text"
                          placeholder="Search by Name, Trx ID"
                          className="filterInput"
                        />{" "}
                        <span className="material-icons icon">search</span>
                      </div>
                    </li> */}
                    {/* <li>
                      <div className="filterDate pr">
                        <DatePicker
                          className="filterInput datepicker"
                          selected={singleDate}
                          onChange={(date) => setSingleDate(date)}
                        />{" "}
                        <span className="material-icons icon">calendar_month</span>
                      </div>
                    </li> */}
                    {/* <li>
                <select className="filterSelect">
                  <option>Payment Source</option>
                </select>
              </li> */}
                    {/* <li>
                      <select className="filterSelect">
                        <option>Status</option>
                      </select>
                    </li> */}
                  </ul>
                </div>
                <div className="dbTable">
                  <table>
                    <tr>
                      <th>Sr. No.</th>
                      <th>From</th>
                      <th>Date & Time</th>
                      <th>Amount</th>
                      <th>Status</th>
                      {/* <th>Action</th> */}
                    </tr>
                    {isLoading ?
                      [...Array(10)].map((data, i) => {
                        return (
                          <>
                            <tr key={i}>
                              <td><Skeleton /></td>
                              <td><Skeleton /></td>
                              <td><Skeleton /></td>
                              <td><Skeleton /></td>
                              <td><Skeleton /></td>
                              <td><Skeleton /></td>
                            </tr>
                          </>

                        )
                      })
                      :
                      requestData?.sent_requests?.map((data: any, i: number) => {
                        return (
                          <tr key={i}>
                            <td>{i + 1 + (perPage - 10)}</td>
                            <td>{data?.from_user_name}</td>
                            <td>{convertUTCDateTimetoLocal(data?.created_at)}</td>
                            <td>${data?.amount}</td>
                            <td><span className={`status ${data?.status.toLowerCase() === "pending" ? "pending" : data?.status.toLowerCase() === "approved" ? "success" : data?.status.toLowerCase() === "rejected" ? "failed" : ""}`}>{data?.status}</span></td>
                            {/* <td><a className="viewLink" onClick={() => { handleShow(); setViewReceiptData(data) }}>View Receipt</a></td> */}
                          </tr>
                        )
                      })
                    }
                  </table>
                </div>

                <div className="flexBox itemCenter justifyContentBetween">
                  <ReactPaginate
                    pageCount={Math.ceil(
                      requestData?.sent_requests_size / 10
                    )}
                    onPageChange={handlePageChange}
                    containerClassName={"paging m-0 p-0 pt-3 d-flex"}
                    activeClassName={"active"}
                    previousLabel={"<"}
                    nextLabel={">"}
                  />
                </div>

              </Tab>

              <Tab eventKey="received" title="Received">
                <div className="flexBox itemCenter justifyContentBetween">
                  <div className="totalNumber">Total: {requestData?.received_requests_size}</div>
                  <ul className="flexBox filter transactionsFilter">
                    {/* <li>
                      <div className="filterSearch pr">
                        <input
                          type="text"
                          placeholder="Search by Name, Trx ID"
                          className="filterInput"
                        />{" "}
                        <span className="material-icons icon">search</span>
                      </div>
                    </li> */}
                    {/* <li>
                      <div className="filterDate pr">
                        <DatePicker
                          className="filterInput datepicker"
                          selected={singleDate}
                          onChange={(date) => setSingleDate(date)}
                        />{" "}
                        <span className="material-icons icon">calendar_month</span>
                      </div>
                    </li> */}
                    {/* <li>
                <select className="filterSelect">
                  <option>Payment Source</option>
                </select>
              </li> */}
                    {/* <li>
                      <select className="filterSelect">
                        <option>Status</option>
                      </select>
                    </li> */}
                  </ul>
                </div>
                <div className="dbTable">
                  <table>
                    <tr>
                      <th>Sr. No.</th>
                      <th>From</th>
                      <th>Date & Time</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                    {isLoading ?
                      [...Array(10)].map((data, i) => {
                        return (
                          <>
                            <tr key={i}>
                              <td><Skeleton /></td>
                              <td><Skeleton /></td>
                              <td><Skeleton /></td>
                              <td><Skeleton /></td>
                              <td><Skeleton /></td>
                              <td><Skeleton /></td>
                            </tr>
                          </>

                        )
                      })
                      :
                      requestData?.received_requests?.map((data: any, i: number) => {
                        return (
                          <tr key={i}>
                            <td>{i + 1 + (perPage - 10)}</td>
                            <td>{data?.from_user_name}</td>
                            <td>{convertUTCDateTimetoLocal(data?.created_at)}</td>
                            <td>${data?.amount}</td>
                            <td><span className={`status ${data?.status.toLowerCase() === "pending" ? "pending" : data?.status.toLowerCase() === "approved" ? "success" : data?.status.toLowerCase() === "rejected" ? "failed" : ""}`}>{data?.status}</span></td>

                            <td className="d-flex" style={{ gap: '10px' }}>
                              <button
                                disabled={data?.status.toLowerCase() === "pending" ? false : true}
                                className="viewLink" onClick={() => { handleRequest({ id: data.id, action: "APPROVED" }) }}
                              >
                                {isActionLoading.state && isActionLoading.id === data.id && isActionLoading.action === "APPROVED" ? "Loading..." : "Approve"}
                              </button>

                              <button
                                disabled={data?.status.toLowerCase() === "pending" ? false : true}
                                className="viewLink" onClick={() => { handleRequest({ id: data.id, action: "REJECTED" }) }}
                              >
                                {isActionLoading.state && isActionLoading.id === data.id && isActionLoading.action === "REJECTED" ? "Loading..." : "Decline"}
                              </button>

                              {/* <a className="viewLink" onClick={() => { handleShow(); setViewReceiptData(data) }}>View Receipt</a> */}

                            </td>
                          </tr>
                        )
                      })
                    }
                  </table>
                </div>

                <div className="flexBox itemCenter justifyContentBetween">
                  <ReactPaginate
                    pageCount={Math.ceil(
                      requestData?.received_requests_size / 10
                    )}
                    onPageChange={handlePageChange}
                    containerClassName={"paging m-0 p-0 pt-3 d-flex"}
                    activeClassName={"active"}
                    previousLabel={"<"}
                    nextLabel={">"}
                  />
                </div>
              </Tab>

            </Tabs>
          </div>

        </div>
        <Modal show={show}>
          <Receipt onClose={handleClose} />
        </Modal>
      </div>
    </>
  );
};

export default Transactions;
