import React, { useState } from 'react'
import Header from '../../../component/Admin/AdminHeader';
import LeftMenu from '../../../component/Admin/AdminLeftMenu';
import "react-datepicker/dist/react-datepicker.css";
import Accordion from 'react-bootstrap/Accordion';
import { NavLink } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
const Detail = () => {
  const faqData = useSelector((state: any) => state.faqs)
  let { key }: any = useParams();
  console.log("🚀 ~ key:", key)
  // const [isActive, setIsActive] = useState(false);
  // const toggleMenu = () => {
  // setIsActive(!isActive);
  // };

  const test = "KYC"

  return (
    <>
      <div className="dbRight">
        <div className="mainHd"><NavLink to='/admin/help-support' className="backLink"><span className="material-icons">west</span></NavLink> Account Setup And Access</div>
        <div className="flexBox helpSupportBox">
          <div className="left">
            <div className="supportBox">
              <Accordion defaultActiveKey="0">
                {faqData?.data?.faqs?.[key]?.map((data: any, index: number) => {
                  console.log("🚀 ~ {faqData?.data?.faq?.map ~ data:", data)
                  return (
                    <Accordion.Item eventKey={index.toString()}>
                      <Accordion.Header>{data?.question}</Accordion.Header>
                      <Accordion.Body>{data?.answer}</Accordion.Body>
                    </Accordion.Item>
                  )
                })}
              </Accordion>
            </div>
          </div>
          {/* <div className="right">
              <span>Still Need Help?</span>
              <a>Request a Call Back</a>
            </div> */}
        </div>

      </div>
    </>

  )
}

export default Detail