import { createAsyncThunk } from "@reduxjs/toolkit";
import supportService from "../../../services/supportService";

interface AsyncFunctionParams {
    rejectWithValue: (value: any) => void;
}

// --------------------------------Get All Tickets---------------------------------


export const getAllTickets = createAsyncThunk(
    'dispute/getAllTickets',
    async (data: { skip: number, limit: number }, params: AsyncFunctionParams) => {
        try {
            const response = await supportService.getAllTickets(data)
            return response
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return params.rejectWithValue(error.response.data.message)
            } else {
                return params.rejectWithValue(error.message)
            }
        }
    }
)