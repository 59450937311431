import React, { useState } from 'react'
import Form from 'react-bootstrap/Form';
import { Navigate, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
interface ReceiptProps {
  onClose: () => void;
  data?: any
}

const Receipt: React.FC<ReceiptProps> = ({ onClose, data }) => {
  const handleModalClose = () => {
    onClose();
  };

  const {
    created_at,
    id,
    payee_id,
    payee_name,
    payee_phone_number,
    payer_id,
    payer_name,
    payer_phone_number,
    payment_amount,
    payment_description,
    payment_method,
    status,
    updated_at,
  } = data

  return (
    <>
      <div>
        <div className="receiptBox">
          <a className="closePopup" onClick={handleModalClose}>
            <span className="material-icons">cancel</span>
          </a>
          <div className="popupHd">Payment Details</div>
          <ul className="paymentDetList p-0 m-0">
            <li className="flexBox itemCenter justifyContentBetween">
              <div className="amount">
                Amount <span>${payment_amount}</span>
              </div>
              <div>
                <span className={`status success`}>{status}</span>
              </div>
            </li>
            <li className="flexBox justifyContentBetween">
              <div className="paidTo">
                <span>Paid To:</span>
                {/* <strong>Swift Mart</strong> */}
                <strong>{payee_name}</strong>
                {payment_method}
                <br />
                {payee_phone_number}
              </div>
              <div>
                <img src="images/user-icon.png" alt="" />
              </div>
            </li>
            <li className="flexBox justifyContentBetween">
              <div>
                <div className="paidTo">
                  <span>Paid From:</span>
                  {/* <strong>Aryanna Miranda</strong> */}
                  <strong>{payer_name}</strong>
                  {payment_method}
                  <br />
                  {payer_phone_number}
                </div>
                <div className="transaction">
                  Transaction ID: {id}
                  {/* <span>12 Mar, 2024 l 10:30 AMd</span> */}
                  <span>{created_at}</span>
                </div>
              </div>
              <div>
                <img src="images/user-img.jpg" alt="" className="roundImg" />
              </div>
            </li>
            <li className="borNone">
              <span className="timelineHd">Timeline</span>
              <ul className="timeline p-0 m-0">
                <li>
                  Payment Started Processing
                  <span>12 Mar, 2024 l 10:30 AM</span>
                </li>
                <li>
                  Merchant Received Payment
                  <span>12 Mar, 2024 l 10:31 AM</span>
                </li>
                <li>
                  Payment Complete
                  <span>You have received a payment of $80</span>
                </li>
              </ul>
            </li>
            {/* <li className="downloadBtns borNone">
              <a className="shareReceipt">
                Share Receipt
              </a>
              <a className="downloadReceipt">
                Download Receipt
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  )
}

export default Receipt