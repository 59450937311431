import { useEffect, useState } from 'react'
import LeftMenu from '../../../component/Admin/AdminLeftMenu';
// import UserDetail from '../../../component/UserDetail';
import UserDetail from '../../../component/Admin/MerchantKYCDetail';

import BlockUser from '../../../component/BlockUser';
import UnblockUser from '../../../component/UnblockUser';
import DatePicker from "react-datepicker";
import Modal from 'react-bootstrap/Modal';
import "react-datepicker/dist/react-datepicker.css";
import walletService from '../../../services/walletService';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { convertUTCDateTimetoLocal } from '../../../utils/timeConverter';

const initialParamState: any = {
  page: 1,
  search: "",
  page_size: 10,
  status: ""
};


const UserManagement = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [paramState, setParamState] = useState(initialParamState);
  const [userData, setUserData] = useState([])
  const [perPage, setPerPage] = useState(10)
  const [reload, setReload] = useState("");
  const [blockFuncId, setBlockFuncId] = useState({});


  const { page, page_size, search, status } = paramState;


  // useEffect(() => {
  //   const data = {
  //     page: 1,
  //     page_size: 10
  //   }
  //   walletService.adminUserList(data).then()
  // }, [])

  useEffect(() => {
    setIsLoading(true)
    let params = { page, page_size };
    walletService.adminUserList(params).then((res: any) => {
      if (!res?.error) {
        console.log(res)
        setUserData(res?.data)
      }
      setIsLoading(false)
    }).catch((err) => {
      setIsLoading(false)

    })

  }, [])

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [isActive, setIsActive] = useState(false);
  const [singleDate, setSingleDate] = useState<Date | null>(new Date());
  return (
    <>
      <div className="dbRight">
        <div className="mainHd">User Management</div>
        <div className="whtBox">
          <div className="flexBox itemCenter justifyContentBetween">
            <div className="totalNumber">Total: 150</div>
            <ul className="flexBox filter">
              <li>
                <div className="filterSearch pr"><input type="text" placeholder="Search by Name, Trx ID"
                  className="filterInput" /> <span className="material-icons icon">search</span></div>
              </li>
              <li>
                <div className="filterDate pr">
                  <DatePicker className='filterInput datepicker' selected={singleDate} onChange={(date) => setSingleDate(date)} />
                  <span className="material-icons icon">calendar_month</span>
                </div>
              </li>
              <li>
                <select className="filterSelect">
                  <option>All</option>
                </select>
              </li>
            </ul>
          </div>
          <div className="dbTable">
            <table>
              <tr>
                <th>Sr. No.</th>
                <th>User Name</th>
                <th>User ID</th>
                <th>Last Transaction Date</th>
                <th>Total Transaction Amount</th>
                <th>Phone Number</th>
                {/* <th>Action</th> */}
              </tr>
              {isLoading ?
                [...Array(10)].map((data, i) => {
                  return (
                    <>
                      <tr key={i}>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                      </tr>
                    </>

                  )
                })
                :
                userData?.map((data: any, i: number) => {
                  return (
                    <tr key={i}>
                      <td>{i + 1 + (perPage - 10)}</td>
                      <td>{data?.payer_name}</td>
                      {/* <td>{convertUTCDateTimetoLocal(data?.created_at)}</td> */}
                      <td>{data?.payer_id}</td>
                      <td>{convertUTCDateTimetoLocal(data?.latest_transaction_date)}</td>
                      <td>${data?.total_payment_amount}</td>
                      <td>{data?.payer_phone_number}</td>
                      {/* <td>
                        <div className="flexBox nowrap">
                          <a className={`viewLink mr10 ${!data?.is_active ? "unblock" : "block"}`} onClick={() => { handleShow2(); setBlockFuncId({ payer_id: data?.payer_id, block_status: data?.is_active }) }}>{!data?.is_active ? "Unblock" : "Block"}</a>
                          <a className="viewLink" onClick={handleShow}>View Details</a>
                        </div>
                      </td> */}
                      {/* <td><span className={`status ${data?.status.toLowerCase() === "pending" ? "pending" : data?.status.toLowerCase() === "approved" ? "success" : data?.status.toLowerCase() === "rejected" ? "failed" : ""}`}>{data?.status}</span></td> */}
                      {/* <td><a className="viewLink" onClick={() => { handleShow(); setViewReceiptData(data) }}>View Receipt</a></td> */}
                    </tr>
                  )
                })
              }
              {/* <tr>
                <td>3</td>
                <td>Gage Barr</td>
                <td>123ABC456</td>
                <td>03/20/2024</td>
                <td>$1530.50</td>
                <td className="nowrap">+65 9345 6789</td>
                <td>
                  <div className="flexBox nowrap">
                    <a className="viewLink mr10" onClick={handleShow2}>Block</a>
                    <a className="viewLink" onClick={handleShow}>View Details</a>
                  </div>
                </td>
              </tr> */}
            </table>
          </div>
        </div>
        <div className="flexBox itemCenter justifyContentBetween">
          <div className="total">Showing 1-10 of 150</div>
          <div className="paging">
            <a><span className="material-icons">chevron_left</span></a>
            <a><span className="material-icons">chevron_right</span></a>
          </div>
        </div>
      </div>
      <Modal show={show}>
        {/* <UserDetail onClose={handleClose} /> */}
      </Modal>
      <Modal show={show2}>
        {/* <BlockUser onClose={handleClose2} /> */}
        <BlockUser setReload={setReload} reload={reload} data={blockFuncId} onClose={handleClose2} />
      </Modal>
      <Modal show={show3}>
        <UnblockUser onClose={handleClose3} />
      </Modal>
    </>

  )
}

export default UserManagement