import { useEffect, useState } from 'react'
import AdminLeftMenu from '../../component/Admin/AdminLeftMenu';
import MerchantLeftMenu from '../../component/Merchant/MerchantLeftMenu';
import "react-datepicker/dist/react-datepicker.css";
import { getLocalAccessToken, getUserType } from '../../services/token.service';
import axios from 'axios';

const base_url = process.env.REACT_APP_SUPPORT_BASE_URL

const Index = () => {
    const [loading, setLoading] = useState(false)
    const [content, setContent] = useState("")

    useEffect(() => {
        setLoading(true)
        axios({
            method: 'get',
            url: `${base_url}admin/legal/privacy`,
        })
            .then(function (response) {
                setContent(response?.data?.data?.content)
                setLoading(false)
            }).catch((err) => {
                setLoading(false)

            })
    }, [])

    const isTokenExist = getLocalAccessToken()
    const userType = getUserType()

    return (
        <>
            <div>
                {isTokenExist && userType === "Admin" ? <AdminLeftMenu /> : ""}
                {isTokenExist && userType === "Merchant" ? <MerchantLeftMenu /> : ""}


                <div className={isTokenExist && (userType === "Admin" || userType === "Merchant") ? "dbRight" : ""}
                >
                    {loading ? <div className="d-flex justify-content-center align-items-center" style={{ width: "100%", height: '65vh' }}> <div className="spinner-border" role="status">
                        <span className="sr-only p-0"></span>
                    </div> </div> : ""}
                    <div dangerouslySetInnerHTML={{ __html: content }}></div>
                </div>
            </div>
        </>

    )
}

export default Index