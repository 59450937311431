
import React, { useState, useRef, useMemo, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import axios from 'axios'
import LeftMenu from '../../../component/Admin/AdminLeftMenu';
import supportService from '../../../services/supportService';
import Modal from "react-bootstrap/Modal";
import PopUp from "../../../component/PopUp";


const PrivacyPolicies = () => {

    const editorPrivacy = useRef(null);
    const editorTnc = useRef(null);
    const [privacyContent, setPrivacyContent] = useState('');
    const [isPrivacyLoading, setIsPrivacyLoading] = useState(false)
    const [tncContent, setTncContent] = useState('');
    const [isTnCLoading, setIsTnCLoading] = useState(false)
    const [popup, setPopup] = useState({ show: false, message: "" })


    const [isActive, setIsActive] = useState(false);
    const toggleMenu = () => {
        setIsActive(!isActive);
    };

    let base_url: any
    // const privacyData = useSWR(`${base_url}/api/admin/privacyPolicies`, fetcher)
    // const tncData = useSWR(`${base_url}/api/admin/tnc`, fetcher)

    let privacyData: any
    let tncData: any
    useEffect(() => {
        setIsPrivacyLoading(true)
        supportService.getPrivacyAndTnCData("privacy").then((data) => {
            setIsPrivacyLoading(false)
            if (!data?.error) {
                setPrivacyContent(data?.data?.content)
            }
        }).catch((err) => {
            setIsPrivacyLoading(false)
            console.error("err : ", err)
        })
    }, [])

    useEffect(() => {
        setIsTnCLoading(true)

        supportService.getPrivacyAndTnCData("terms").then((data) => {
            setIsTnCLoading(false)
            if (!data?.error) {
                setTncContent(data?.data?.content)

            }
        }).catch((err) => {
            setIsTnCLoading(false)
            console.error("err : ", err)
        })
    }, [])


    const handleSavePrivacy = () => {
        setIsPrivacyLoading(true)
        const data = { content: privacyContent }
        setPopup({ show: false, message: "" })
        supportService.addPrivacyAndTnCData(data, "privacy").then((res) => {
            setIsPrivacyLoading(false)
            if (!res.error) {
                setPopup({ show: true, message: res?.message })

            }
        }).catch((err) => {
            setIsPrivacyLoading(false)
            console.error("err : ", err)
        })
    }


    const handleSaveTnC = () => {
        setIsTnCLoading(true)
        const data = { content: tncContent }
        setPopup({ show: false, message: "" })
        supportService.addPrivacyAndTnCData(data, "terms").then((res) => {
            setIsTnCLoading(false)
            if (!res.error) {
                setPopup({ show: true, message: res?.message })
            }
        }).catch((err) => {
            setIsTnCLoading(false)
            console.error("err : ", err)
        })
    }


    return (
        <>
            {/* <div className={isActive ? "dbCon active" : "dbCon"}>
                <LeftMenu /> */}
            <div className="dbRight">
                <div>
                    <div className="mb-3">
                        <label htmlFor="exampleFormControlTextarea1" className="form-label">Enter privacy policies</label>
                        <JoditEditor
                            ref={editorPrivacy}
                            value={privacyContent}
                            tabIndex={1}
                            onBlur={newContent => setPrivacyContent(newContent)} // preferred to use only this option to update the 
                        />
                    </div>

                    <button onClick={handleSavePrivacy} disabled={isPrivacyLoading || !privacyContent} type="button" className="btn btn-primary">{isPrivacyLoading ? "Loading..." : "Save"}</button>
                </div>

                <div className='my-5'>

                    <div className="mb-3">
                        <label htmlFor="exampleFormControlTextarea1" className="form-label">Enter Terms and Conditions</label>
                        <JoditEditor
                            ref={editorTnc}
                            value={tncContent}
                            tabIndex={1}
                            onBlur={newContents => setTncContent(newContents)} // preferred to use only this option to update the 
                        />
                    </div>

                    <button onClick={handleSaveTnC} type="button" disabled={isTnCLoading} className="btn btn-primary">{isTnCLoading ? "Loading..." : "Save"}</button>
                </div>
            </div>
            <Modal show={popup.show}><PopUp message={popup.message} onClose={() => setPopup({ show: false, message: "" })} /></Modal>
        </>
    )
}

export default PrivacyPolicies