import React, { useState, Dispatch, SetStateAction } from 'react';
import Button from 'react-bootstrap/Button';
import { sendPaymentInterface } from "../../../services/interface/bank.interface"
import walletService from "../../../services/walletService"
import identityService from "../../../services/identityService"
import PopUp from "../../../component/PopUp";
import Modal from "react-bootstrap/Modal";

interface UpdateProps {
  onClose: () => void;
  setWalletLoader: Dispatch<SetStateAction<boolean>>;
  walletLoader: boolean
}

const initialState: sendPaymentInterface = {
  destination_phone_number: "",
  amount: "",
  message: ""
}

const RequestMoney: React.FC<UpdateProps> = ({ onClose, setWalletLoader, walletLoader }) => {
  const [modalStep, setModalStep] = React.useState<number>(1);
  const [iState, updateState] = useState<sendPaymentInterface>(initialState)
  const [isloading, setIsloading] = useState<boolean>(false)
  const [popup, setPopup] = useState({ show: false, message: "" })
  const [error, setError] = useState<any>({})

  const { amount, destination_phone_number, message } = iState

  const handleClose = () => {
    onClose();
  };

  const changeBankAccountForm = (e: any) => {
    const { value, name } = e.target
    const regex = /^\d+$/;
    const amountRegex = /^$|^[1-9]\d*(\.\d{1,2})?$/;

    if (name === "destination_phone_number" && regex.test(value)) {
      updateState({
        ...iState,
        [name]: value
      })
    }

    if (name === "amount" && amountRegex.test(value)) {
      updateState({
        ...iState,
        [name]: value
      })
    }


  }

  // handle form validation
  let handleValidation = () => {
    let destinationPhoneNumberEmpty
    let amountEmpty
    let formIsValid = true;

    if (!destination_phone_number.trim()) {
      destinationPhoneNumberEmpty = "Please enter phone.";
      formIsValid = false;
    }

    if (!amount.trim()) {
      amountEmpty = "Please enter amount.";
      formIsValid = false;
    }

    setError({
      destinationPhoneNumberEmpty,
      amountEmpty,
      formIsValid,
    });
    return formIsValid;
  };

  const handleSendMoney = (e: any) => {
    e.preventDefault()
    const isValid = handleValidation()
    if (!isValid) {
      return
    }

    setIsloading(true)

    identityService.getUserIdByPhoneNo({ phone_number: destination_phone_number }).then((res) => {
      if (!res.error) {
        const payload = {
          to_user_id: res?.data?.user_id,
          amount: Number(amount),
          message
        }

        walletService.sendMerchantRequestMoney(payload).then((res) => {
          setIsloading(false)
          if (!res?.error) {
            // setWalletLoader(!walletLoader)
            // setModalStep(2)
            setPopup({ show: true, message: res?.message })
          }
        }).catch((err) => {
          setPopup({ show: true, message: err?.response?.data?.message })
          setIsloading(false)
        })
      }
    }).catch((err) => {
      setIsloading(false)
      setPopup({ show: true, message: err?.response?.data?.message })
    })


  }

  return (
    <>
      <div>
        {modalStep === 1 && (
          <div className="popupBox">
            <a className="closePopup" onClick={handleClose}>
              <span className="material-icons">cancel</span>
            </a>
            <div className="popupHd tac">Send Payment</div>
            <form onSubmit={handleSendMoney} onChange={changeBankAccountForm} >
              <ul className="loginForm m-0 p-0">

                <li>
                  <input value={destination_phone_number} name="destination_phone_number" type="text" placeholder="Enter Number" className="formInput" />
                  <span style={{ color: "red" }}>
                    {error?.destinationPhoneNumberEmpty}
                  </span>
                </li>
                <li>
                  <input value={amount} name="amount" type="text" placeholder="Enter Amount" className="formInput" />
                  <span style={{ color: "red" }}>
                    {error?.amountEmpty}
                  </span>
                </li>

                <li>
                  <input value={message} name="message" type="text" placeholder="Enter Message (Optional)" className="formInput" />
                </li>

                <li>
                  <Button disabled={isloading} className="loginBtn" type="submit">{isloading ? "Loading..." : "Proceed Securely"}</Button>
                </li>
              </ul>
            </form>
          </div>
        )}

        {modalStep === 2 && (
          <div className="popupBox" style={{ padding: "80px" }}>
            <a className="closePopup" onClick={handleClose}>
              <span className="material-icons">cancel</span>
            </a>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                borderRadius: "12px",
                background: "#44B678",
                boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.1)",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  color: "#FFFFFF",
                  fontSize: "20px",
                  marginTop: "35px"
                }}
              >
                Payment Sent Successfully
              </p>
              <h1
                style={{
                  fontSize: "50px",
                  fontWeight: "normal",
                  color: "#FFFFFF"
                }}
              >${amount}</h1>
            </div>

            <div
              style={{
                height: "470px",
                borderRadius: "12px",
                background: "#FFFFFF",
                boxSizing: "border-box",
                border: "1px solid #DEDEDE",
                boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.1)",
                marginTop: "15px",
                padding: "25px 20px"
              }}
            >

              <div
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div style={{ lineHeight: "1.5" }}>
                  <div>To:</div>
                  <div style={{ fontSize: "18px" }}>Devon Lane</div>
                  <div>EazyPay Wallet</div>
                  <div>(+65) 555-0127</div>
                </div>
                <div>
                  <img src="/images/chat_bot.png" height={45} alt="receiver img" />
                </div>
              </div>

              <div style={{
                height: "1px",
                background: "#DCDCDC",
                margin: "25px 0"
              }} ></div>

              <div
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div style={{ lineHeight: "1.5" }}>
                  <div>From:</div>
                  <div style={{ fontSize: "18px" }}>Devon Lane</div>
                  <div>EazyPay Wallet</div>
                  <div>(+65) 555-0127</div>
                </div>
                <div>
                  <img src="/images/user-icon.png" height={45} alt="receiver img" />
                </div>
              </div>

              <div style={{ lineHeight: "1.5", marginTop: "15px" }}>
                <div>Transaction ID: 21365479541236</div>
                <div style={{ color: "#8A8A8D" }}>12 Mar, 2024  |  10:30 AM</div>
              </div>

              <div style={{
                height: "1px",
                background: "#DCDCDC",
                margin: "25px 0"
              }} ></div>

              <div className='d-flex justify-content-center'>
                <img src="/images/mini-logo.png" alt="logo" />
              </div>

            </div>

            <div className='d-flex justify-content-center' style={{ position: "absolute", bottom: "30px", left: "170px" }}>
              <img src="/images/secure.png" alt="100% secure payment" />
            </div>

          </div>
        )}

        {/* {modalStep === 3 && (
          <div className="popupBox">
            <a className="closePopup" onClick={handleClose}>
              <span className="material-icons">cancel</span>
            </a>
            <div className="tac sucessfull">
              <span className="material-icons-outlined">verified</span>
            </div>
            <div className="popupHd tac">Bank Added Successfully!</div>
            <Button className="loginBtn" onClick={handleClose}>Close</Button>
          </div>
        )} */}
      </div>
      <Modal show={popup.show}><PopUp message={popup.message} onClose={() => setPopup({ show: false, message: "" })} /></Modal>
    </>
  );
};

export default RequestMoney;
