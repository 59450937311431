import axios from "axios";
import { getLocalAccessToken, getLocalRefreshToken, getUserType, updateLocalAccessToken } from "../token.service";

const walletInstance = axios.create({
    baseURL: process.env.REACT_APP_SUPPORT_BASE_URL,
    headers: {
        "Content-Type": "application/json",
    },
    // withCredentials: true,
});

walletInstance.interceptors.request.use(
    (config) => {
        const token = getLocalAccessToken();
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;
            // config.headers["withCredentials"] = true;
            // config.headers["x-access-token"] = token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

walletInstance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        let val = 0
        if (originalConfig.url !== "/merchants/login" && originalConfig.url !== "/admin/login" && err.response) {
            // Access Token was expired
            if (err.response.status === 401) {
                const refreshToken = await getLocalRefreshToken()
                const userType = await getUserType()
                try {
                    axios.get(`${process.env.REACT_APP_IDENTITY_BASE_URL}/users/refresh`, {
                        headers: {
                            Authorization: `Bearer ${refreshToken}`
                        }
                    }).then(async (res) => {

                        await updateLocalAccessToken(res.data, userType);
                        window.location.reload()
                        return
                    }).catch((err)=>{
                        localStorage.removeItem("user")
                        alert("your session has ended.")
                        window.location.replace("/")
                    })
                } catch (_error) {
                    return Promise.reject(_error);
                }
            }
        }

        return Promise.reject(err);
    }
);

export default walletInstance;