import React, { useState, useEffect } from "react";
import LeftMenu from "../../../component/Merchant/MerchantLeftMenu";
import Receipt from "../../../component/Receipt";
import DatePicker from "react-datepicker";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { getMerchantTransactions } from '../../../redux/features/transaction/transactionAction';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from "../../../redux/store"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { convertUTCDateTimetoLocal } from "../../../utils/timeConverter"
import ReactPaginate from 'react-paginate';
import { useDebounce } from 'use-debounce';
import walletService from "../../../services/walletService";


import Accordion from 'react-bootstrap/Accordion';
import AddFAQTitle from "../../../component/Admin/AddFAQTitle";
import { getFAQs } from '../../../redux/features/support/supportAction';
import supportService from "../../../services/supportService";
import EditFAQ from "../../../component/Admin/EditFAQ";


const initialParamState: any = {
  page: 1,
  search: "",
  page_size: 10,
  status: ""
};


const FAQsManagement = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isActive, setIsActive] = useState(false);
  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  // for create faq modal
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // for edit faq modal
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const dispatch: AppDispatch = useDispatch()
  const [faqLoader, setFaqLoader] = useState(false)
  const [faqActionLoading, setFaqActionLoading] = useState({ id: "", state: false })
  const [faqEditData, setFaqEditData] = useState({ id: '', title: '', question: '', answer: '' })


  const faqData = useSelector((state: any) => state.faqs)

  useEffect(() => {
    dispatch(getFAQs())
  }, [faqLoader])


  const handleDeleteQA = (id: any) => {
    setFaqActionLoading({ id: id, state: true })
    supportService.deleteFAQ(id).then((res: any) => {
      setFaqActionLoading({ id: id, state: false })
      setFaqLoader(!faqLoader)
    }).catch((err) => {
      setFaqActionLoading({ id: id, state: false })
      console.error("err : ", err)
    })
  }




  return (
    <>
      <div className="dbRight">
        <div className="mainHd">FAQs</div>
        <div className="whtBox">
          <Accordion defaultActiveKey="0">
            <div className="mb-4" style={{ textAlign: "end" }}>
              <button className="viewLink mr10 p-3" onClick={handleShow1} >Create FAQs</button>
            </div>

            {faqData?.loading ? <div><Skeleton style={{ margin: ".5rem 0", borderRadius: "14px" }} height={"25rem"} /></div> : ""}
            {faqData?.loading ?
              [...Array(10)].map((data, i) => {
                return (
                  <>

                    <div key={i}><Skeleton style={{ margin: ".5rem 0", borderRadius: "14px" }} height={"5rem"} /></div>

                  </>

                )
              })
              : ""}

            {faqData?.data?.titles?.map((elem: any, i: any) => {
              return (
                <Accordion.Item eventKey={i?.toString()}>
                  <Accordion.Header>{elem}</Accordion.Header>
                  <Accordion.Body>
                    <div className="dbTable">
                      <table>
                        <tr>
                          <th>Id</th>
                          <th>Question</th>
                          <th>Answer</th>
                          <th>View</th>
                          <th>Action</th>
                        </tr>

                        {faqData?.data?.faqs?.[elem]?.map((data: any) => {
                          return (
                            <tr key={i}>
                              <td>{data?.id}</td>
                              <td>{data?.question}</td>
                              <td>{data?.answer}</td>
                              <td>
                                <div className="flexBox nowrap">
                                  <button
                                    className="viewLink mr10"
                                    // disabled={isAcceptLoadingId === data.ew_user_id || data.ew_eKYC_verification_status === "Verified"}
                                    onClick={() => { setFaqEditData({ id: data?.id, title: data?.title, question: data?.question, answer: data?.answer }); handleShow2() }}
                                  >
                                    {"Edit"}
                                  </button>

                                  <button
                                    className="viewLink mr10"
                                    onClick={() => handleDeleteQA(data?.id)}
                                  >
                                    {faqActionLoading.id == data?.id && faqActionLoading.state ? "Loading..." : "Delete"}
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </table>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              )
            })}
            {/* <Accordion.Item eventKey="0">
              <Accordion.Header>Accordion Item #1</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>Accordion Item #2</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item> */}
          </Accordion>

        </div>
      </div>

      {/* Modals to Create Faqs with group */}
      <Modal show={show1}>
        <AddFAQTitle
          onClose={handleClose1}
          setFaqLoader={setFaqLoader}
          faqLoader={faqLoader}
        />
      </Modal>

      {/* Modals to Edit Faq */}
      <Modal show={show2}>
        <EditFAQ
          onClose={handleClose2}
          setFaqLoader={setFaqLoader}
          faqLoader={faqLoader}
          data={faqEditData}
        />
      </Modal>
    </>
  );
};

export default FAQsManagement;
