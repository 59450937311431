import React, { useEffect, useState } from 'react'
import Header from '../../../component/Admin/AdminHeader';
import LeftMenu from '../../../component/Merchant/MerchantLeftMenu';
import Receipt from '../../../component/Receipt';
import DatePicker from "react-datepicker";
import Modal from 'react-bootstrap/Modal';
import "react-datepicker/dist/react-datepicker.css";
import walletService from '../../../services/walletService';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { convertUTCDateTimetoLocal } from "../../../utils/timeConverter"
import { getMerchantTransactions } from '../../../redux/features/transaction/transactionAction';
import { AppDispatch } from "../../../redux/store"
import ReactPaginate from 'react-paginate';


const initialParamState: any = {
  page: 1,
  search: "",
  page_size: 10,
  status: ""
};

const Index = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch: AppDispatch = useDispatch()
  const [isActive, setIsActive] = useState(false);
  const [dashboardData, setDashboardData] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)
  const [perPage, setPerPage] = useState(10)
  const [viewReceiptData, setViewReceiptData] = useState();
  const transactionData = useSelector((state: any) => state.transaction)
  const [paramState, setParamState] = useState(initialParamState);
  const { page, page_size, search, status } = paramState;

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    setIsLoading(true)
    walletService.getMerchantDashboard().then((res: any) => {
      setIsLoading(false)
      if (!res.error) {
        setDashboardData(res.data)
      }
    }).catch((err) => {

    })
  }, [])


  useEffect(() => {
    let params = { page, page_size };
    dispatch(getMerchantTransactions(params))

  }, [])

  const handlePageChange = async ({ selected }: any) => {
    let selectedPage = selected + 1;
    setPerPage(10 * Number(selectedPage));
    let params: any = { page: selectedPage, page_size };
    await dispatch(getMerchantTransactions(params));
    setParamState({
      page: selectedPage,
      page_size,
    })
  };

  const [singleDate, setSingleDate] = useState<Date | null>(new Date());
  return (
    <>
      <div className="dbRight">
        <div className="mainHd">Dashboard</div>
        <ul className="flexBox statsList p-0 m-o">
          <li>
            <div className="inner">
              <div className="top flexBox justifyContentBetween">
                <div className="hd">
                  Total User <span>{isLoading ? <Skeleton height={35} /> : dashboardData?.total_users}</span>
                </div>
                <div className="icon" style={{ background: "#E4E4FF" }}>
                  <img src="../images/total-user.png" alt="Total User" />
                </div>
              </div>
              <div className="bot flexBox">
                <img src="../images/graph1.png" alt="" />{" "}
                <span className="number green">8.5%</span> Up from past week
              </div>
            </div>
          </li>
          <li>
            <div className="inner">
              <div className="top flexBox justifyContentBetween">
                <div className="hd">
                  Total Amount in Wallet <span>{isLoading ? <Skeleton height={35} /> : `$ ${dashboardData?.balance}`}</span>
                </div>
                <div className="icon" style={{ background: "#FEF2D6" }}>
                  <img src="../images/wallet.png" alt="Total Amount in Wallet" />
                </div>
              </div>
              <div className="bot flexBox">
                <img src="../images/graph2.png" alt="" />{" "}
                <span className="number red">2.1%</span> Down from past week
              </div>
            </div>
          </li>
          <li>
            <div className="inner">
              <div className="top flexBox justifyContentBetween">
                <div className="hd">
                  Total Transactions <span>{isLoading ? <Skeleton height={35} /> : dashboardData?.total_transactions}</span>
                </div>
                <div className="icon" style={{ background: "#D9F7E7" }}>
                  <img
                    src="../images/total-transactions.png"
                    alt="Total Transactions"
                  />
                </div>
              </div>
              <div className="bot flexBox">
                <img src="../images/graph1.png" alt="" />{" "}
                <span className="number green">5.4%</span> Up from past week
              </div>
            </div>
          </li>
          {/* <li>
            <div className="inner">
              <div className="top flexBox justifyContentBetween">
                <div className="hd">
                  Pending Help Queries <span>0</span>
                </div>
                <div className="icon" style={{ background: "#FFDED2" }}>
                  <img src="../images/pending-help.png" alt="Pending Help Queries" />
                </div>
              </div>
              <div className="bot flexBox">
                <img src="../images/graph1.png" alt="" />{" "}
                <span className="number green">3.1%</span> Up from past week
              </div>
            </div>
          </li> */}
          {/* <li>
            <div className="inner">
              <div className="top flexBox justifyContentBetween">
                <div className="hd">
                  Donation Received <span>0</span>
                </div>
                <div className="icon" style={{ background: "#B3F8EE" }}>
                  <img
                    src="../images/donation-received.png"
                    alt="Donation Received"
                  />
                </div>
              </div>
              <div className="bot flexBox">
                <img src="../images/graph1.png" alt="" />{" "}
                <span className="number green">3.2%</span> Up from past week
              </div>
            </div>
          </li> */}
        </ul>
        <div className="whtBox mt20">
          <div className="flexBox itemCenter justifyContentBetween">
            <div className="dbSubHd">Recent Transactions</div>

          </div>
          <div className="dbTable">
            <table>
              <tbody>
                <tr>
                  <th>Sr. No.</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Trx ID.</th>
                  <th>Payment Source</th>
                  <th>Date &amp; Time</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
                {transactionData?.loading ?
                  [...Array(10)].map((data, i) => {
                    return (
                      <>
                        <tr key={i}>
                          <td><Skeleton /></td>
                          <td><Skeleton /></td>
                          <td><Skeleton /></td>
                          <td><Skeleton /></td>
                          <td><Skeleton /></td>
                          <td><Skeleton /></td>
                          <td><Skeleton /></td>
                          <td><Skeleton /></td>
                          <td><Skeleton /></td>
                        </tr>
                      </>

                    )
                  })
                  :
                  transactionData?.data?.data?.map((data: any, i: number) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1 + (perPage - 10)}</td>
                        <td>{data?.payer_name}</td>
                        <td>{data?.payee_name}</td>
                        <td>{data?.id}</td>
                        <td>{data?.payment_method?.toUpperCase()}</td>
                        <td>{convertUTCDateTimetoLocal(data?.created_at)}</td>
                        <td>${data?.payment_amount}</td>
                        <td><span className={`status ${data?.status.toLowerCase()}`}>{data?.status}</span></td>
                        <td><a className="viewLink" onClick={() => { handleShow(); setViewReceiptData(data) }}>View Receipt</a></td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
        {/* <div className="flexBox itemCenter justifyContentBetween">
          <ReactPaginate
            pageCount={Math.ceil(
              transactionData?.data?.total / 10
            )}
            onPageChange={handlePageChange}
            containerClassName={"paging m-0 p-0 pt-3 d-flex"}
            activeClassName={"active"}
            previousLabel={"<"}
            nextLabel={">"}
          />
        </div> */}
      </div>

      <Modal show={show}>
        <Receipt onClose={handleClose} data={viewReceiptData} />
      </Modal>
    </>

  )
}

export default Index