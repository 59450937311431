import React, { useEffect, useState } from 'react'
import Header from '../../../component/Admin/AdminHeader';
import LeftMenu from '../../../component/Admin/AdminLeftMenu';
import Receipt from '../../../component/Receipt';
import DatePicker from "react-datepicker";
import Modal from 'react-bootstrap/Modal';
import "react-datepicker/dist/react-datepicker.css";
import { AppDispatch } from "../../../redux/store"
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { getTransactions } from '../../../redux/features/transaction/transactionAction';
import { convertUTCDateTimetoLocal } from "../../../utils/timeConverter"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const initialParamState: any = {
    page: 1,
    search: "",
    page_size: 10,
    status: ""
};

const Transactions = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isActive, setIsActive] = useState(false);
    const toggleMenu = () => {
        setIsActive(!isActive);
    };
    const [singleDate, setSingleDate] = useState<Date | null>(new Date());
    const dispatch: AppDispatch = useDispatch()
    const transactionData = useSelector((state: any) => state.transaction)
    const [perPage, setPerPage] = useState(10)
    const [paramState, setParamState] = useState(initialParamState);
    const [viewReceiptData, setViewReceiptData] = useState();
    const { page, page_size, search, status } = paramState;
    const [value] = useDebounce(search, 300);

    useEffect(() => {
        let params = { page, page_size, search: value };
        dispatch(getTransactions(params))

    }, [])

    const handlePageChange = async ({ selected }: any) => {
        let selectedPage = selected + 1;
        setPerPage(10 * Number(selectedPage));
        let params: any = { page: selectedPage, page_size };
        await dispatch(getTransactions(params));
        setParamState({
            page: selectedPage,
            page_size,
        })
    };



    return (
        <>
            <div className="dbRight">
                <div className="mainHd">Transactions</div>
                <div className="whtBox">
                    <div className="flexBox itemCenter justifyContentBetween">
                        <div className="totalNumber">Total: 500</div>
                        {/* <ul className="flexBox filter transactionsFilter">
                            <li>
                                <div className="filterSearch pr"><input type="text" placeholder="Search by Name, Trx ID"
                                    className="filterInput" /> <span className="material-icons icon">search</span></div>
                            </li>
                            <li>
                                <div className="filterDate pr"><DatePicker className='filterInput datepicker' selected={singleDate} onChange={(date) => setSingleDate(date)} /> <span
                                    className="material-icons icon">calendar_month</span></div>
                            </li>
                            <li>
                                <select className="filterSelect">
                                    <option>Payment Source</option>
                                </select>
                            </li>
                            <li>
                                <select className="filterSelect">
                                    <option>Status</option>
                                </select>
                            </li>
                        </ul> */}
                    </div>
                    <div className="dbTable">
                        <table>
                            <tr>
                                <th>Sr. No.</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Trx ID.</th>
                                <th>Payment Source</th>
                                <th>Date & Time</th>
                                <th>Amount</th>
                                <th>Status</th>
                                {/* <th>Action</th> */}
                            </tr>
                            {transactionData?.loading ?
                                [...Array(10)].map((data, i) => {
                                    return (
                                        <>
                                            <tr key={i}>
                                                <td><Skeleton /></td>
                                                <td><Skeleton /></td>
                                                <td><Skeleton /></td>
                                                <td><Skeleton /></td>
                                                <td><Skeleton /></td>
                                                <td><Skeleton /></td>
                                                <td><Skeleton /></td>
                                                <td><Skeleton /></td>
                                                {/* <td><Skeleton /></td> */}
                                            </tr>
                                        </>

                                    )
                                })
                                :
                                transactionData?.data?.data?.map((data: any, i: number) => {
                                    return (
                                        <tr key={i}>
                                            <td>{i + 1 + (perPage - 10)}</td>
                                            <td>{data?.payer_name}</td>
                                            <td>{data?.payee_name}</td>
                                            <td>{data?.id}</td>
                                            <td>{data?.payment_method?.toUpperCase()}</td>
                                            <td>{convertUTCDateTimetoLocal(data?.created_at)}</td>
                                            <td>${data?.payment_amount}</td>
                                            <td><span className={`status ${data?.status.toLowerCase()}`}>{data?.status}</span></td>
                                            {/* <td><a className="viewLink" onClick={() => { handleShow(); setViewReceiptData(data) }}>View Receipt</a></td> */}
                                        </tr>
                                    )
                                })
                            }
                            {/* <tr>
                            <td>1</td>
                            <td>Valentina Meza</td>
                            <td>7c16abcfa14f998355</td>
                            <td>EazyPay Wallet</td>
                            <td>03/20/2024<br /> 10:30 AM</td>
                            <td>$50.00</td>
                            <td><span className="status success">Success</span></td>
                            <td><a className="viewLink" onClick={handleShow}>View Receipt</a></td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Cooper Davies</td>
                            <td>7c16abcfa14f998355</td>
                            <td>EazyPay Wallet</td>
                            <td>03/20/2024<br /> 10:30 AM</td>
                            <td>$50.00</td>
                            <td><span className="status success">Success</span></td>
                            <td><a className="viewLink" onClick={handleShow}>View Receipt</a></td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Aubree Mckay</td>
                            <td>7c16abcfa14f998355</td>
                            <td>Citi Bank Singapore</td>
                            <td>03/20/2024<br /> 10:30 AM</td>
                            <td>$50.00</td>
                            <td><span className="status failed">Failed</span></td>
                            <td><a className="viewLink" onClick={handleShow}>View Receipt</a></td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>Anderson Villegas</td>
                            <td>7c16abcfa14f998355</td>
                            <td>EazyPay Wallet</td>
                            <td>03/20/2024<br /> 10:30 AM</td>
                            <td>$50.00</td>
                            <td><span className="status success">Success</span></td>
                            <td><a className="viewLink" onClick={handleShow}>View Receipt</a></td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>London Leblanc</td>
                            <td>7c16abcfa14f998355</td>
                            <td>HSBC</td>
                            <td>03/20/2024<br /> 10:30 AM</td>
                            <td>$50.00</td>
                            <td><span className="status pending">Pending</span></td>
                            <td><a className="viewLink" onClick={handleShow}>View Receipt</a></td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>Matthias Keller</td>
                            <td>7c16abcfa14f998355</td>
                            <td>EazyPay Wallet</td>
                            <td>03/20/2024<br /> 10:30 AM</td>
                            <td>$50.00</td>
                            <td><span className="status failed">Failed</span></td>
                            <td><a className="viewLink" onClick={handleShow}>View Receipt</a></td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td>Iliana Boyd</td>
                            <td>7c16abcfa14f998355</td>
                            <td>Standard Chartered Bank</td>
                            <td>03/20/2024<br /> 10:30 AM</td>
                            <td>$50.00</td>
                            <td><span className="status success">Success</span></td>
                            <td><a className="viewLink" onClick={handleShow}>View Receipt</a></td>
                        </tr> */}
                        </table>
                    </div>
                </div>
                <div className="flexBox itemCenter justifyContentBetween">
                    <div className="total">Showing 1-10 of 150</div>
                    <div className="paging">
                        <a><span className="material-icons">chevron_left</span></a>
                        <a><span className="material-icons">chevron_right</span></a>
                    </div>
                </div>
            </div>
            <Modal show={show}>
                <Receipt onClose={handleClose} />
            </Modal>
        </>

    )
}

export default Transactions