import { useState, useRef, useEffect } from 'react'
import 'react-chat-elements/dist/main.css';
import { Input, ChatItem } from 'react-chat-elements';
import MessageList from "../../../component/Admin/MessageList"
import { getLocalAccessToken } from '../../../services/token.service';
const token = getLocalAccessToken();

let clearRef = () => { }

const Index = () => {
  const [messages, setMessages] = useState<any>([]);
  const [inputValue, setInputValue] = useState('');
  const [socket, setSocket] = useState<any>(null)
  
  useEffect(() => {
    const ws = new WebSocket(`${process.env.REACT_APP_WEB_SOCKET_URL}/ws/admin/123456`)
    setSocket(ws)

    ws.onopen = () => {
      console.log('WebSocket connection established');
    };

    ws.onmessage = (event) => {
      const message = {
        text: event.data,
        position: "left"
      }
      setMessages((prevMessages: any) => [...prevMessages, message]);
    };


    return () => {
      ws.close();
    };
  }, []);

  const inputReferance = useRef()
  const handleSend = () => {
    if (socket) {
      socket.send(inputValue)
    }
    if (inputValue.trim()) {
      const newMessage = {
        position: 'right',
        text: inputValue,
      };
      setMessages([...messages, newMessage]);
      setInputValue('');
    }
  };

  return (

      <div className='dbRight'>
        <div
          style={{
            alignItems: "center",
            backgroundColor: "#fff",
            color: "#000",
            colorScheme: "light",
            display: "flex",
            height: "88%",
            justifyContent: "space-between",
            position: "absolute",
            width: "82%"
          }}>
          <div
            style={{
              borderRight: "solid #e7e7e7", height: "100%", width: "25%"
            }}
          >

            <ChatItem
              avatar="https://avatars.githubusercontent.com/u/41473129?v=4"
              alt="kursat_avatar"
              title="Emre"
              subtitle={messages[messages.length-1]?.text}
              date={new Date()}
              muted={true}
              showMute={true}
              showVideoCall={true}
              unread={2}
            />
          </div>

          <div
            style={{
              height: "100%",
              width: "75%"
            }}
          >

            <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
              <div style={{
                alignItems: "center",
                borderBottom: "solid #e7e7e7",
                display: "flex",
                height: "15%",
                marginLeft: "2%"
              }}>
                <div style={{
                  height: "55px",
                  width: "55px"
                }}>
                  <img src="https://avatars.githubusercontent.com/u/80540635?v=4" alt="chat image" />
                </div>
                <div style={{
                  width: "80%"
                }}>
                  <label style={{
                    fontWeight: 700,
                    marginLeft: "2%"
                  }} >Username</label>
                  <label style={{
                    display: "block",
                    fontSize: "small",
                    marginLeft: "2%"
                  }} >Online</label>
                </div>
              </div>
              <div style={{ flex: 1, overflowY: 'scroll' }}>

                <MessageList list={messages} />

              </div>
              <div style={{ padding: '10px' }}>
                <Input
                  placeholder='Type a message...'
                  multiline={true}
                  value={inputValue}
                  referance={inputReferance}
                  clear={(clear: any) => (clearRef = clear)}
                  maxHeight={50}
                  onKeyPress={(e: any) => {
                    if (e.shiftKey && e.charCode === 13) {
                      return true
                    }
                    if (e.charCode === 13) {
                      clearRef()
                    }
                  }}
                  onChange={(e: any) => setInputValue(e.target.value)}
                  rightButtons={
                    <button onClick={handleSend} style={{ background: 'blue', color: 'white', border: 'none', padding: '5px 10px', borderRadius: '5px' }} className="sbtn">
                      Send
                    </button>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Index