import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { sendPaymentInterface } from "../../services/interface/bank.interface"
import walletService from "../../services/walletService"
import identityService from "../../services/identityService"
import supportService from "../../services/supportService"
import PopUp from "../PopUp";
import Modal from "react-bootstrap/Modal";

interface UpdateProps {
    onClose: () => void;
    setFaqLoader: Dispatch<SetStateAction<boolean>>;
    faqLoader: boolean
    data: any;
}

const initialState: any = {
    id: "",
    title: "",
    question: "",
    answer: ""
}

const EditFAQ: React.FC<UpdateProps> = ({ onClose, setFaqLoader, faqLoader, data }) => {
    const [modalStep, setModalStep] = React.useState<number>(1);
    const [iState, updateState] = useState<any>(initialState)
    const [isloading, setIsloading] = useState<boolean>(false)
    const [popup, setPopup] = useState({ show: false, message: "" })
    const [error, setError] = useState<any>({})

    useEffect(() => {
        console.log("🚀 ~ useEffect ~ data:", data)
        updateState(data)
    }, [])

    const { id, question, title, answer } = iState

    const handleClose = () => {
        onClose();
    };

    const changeFAQForm = (e: any) => {
        const { value, name } = e.target
        updateState({
            ...iState,
            [name]: value
        })
    }

    // handle form validation
    let handleValidation = () => {
        let questionEmpty
        let answerEmpty
        let formIsValid = true;

        if (!question?.trim()) {
            questionEmpty = "Please enter question.";
            formIsValid = false;
        }

        if (!answer?.trim()) {
            answerEmpty = "Please enter answer.";
            formIsValid = false;
        }

        setError({
            questionEmpty,
            answerEmpty,
            formIsValid,
        });
        return formIsValid;
    };

    // const handleAddFAQ = (e: any) => {
    //     e.preventDefault()
    //     const isValid = handleValidation()
    //     if (!isValid) {
    //         return
    //     }

    //     setIsloading(true)
    //     supportService.AddFAQ(iState).then((res: any) => {
    //         setIsloading(false)
    //         setFaqLoader(!faqLoader)
    //     }).catch((err) => {
    //         setIsloading(false)
    //         setPopup({ show: true, message: err?.response?.data?.message })
    //     })
    // }

    const handleEditQA = (e: any) => {
        e.preventDefault()
        const isValid = handleValidation()
        if (!isValid) {
            return
        }
        setIsloading(true)
        supportService.editFAQ({ question, answer }, id).then((res: any) => {
            console.log("🚀 ~ supportService.editFAQ ~ res:", res)
            setIsloading(false)
            setFaqLoader(!faqLoader)
            setModalStep(2)
        }).catch((err) => {
            setIsloading(false)
            console.error("err : ", err)
        })
    }

    return (
        <>
            <div>
                {modalStep === 1 && (
                    <div className="popupBox">
                        <a className="closePopup" onClick={handleClose}>
                            <span className="material-icons">cancel</span>
                        </a>
                        <div className="popupHd tac">Edit FAQ Title</div>
                        <form onSubmit={handleEditQA} onChange={changeFAQForm} >
                            <ul className="loginForm m-0 p-0">
                                <li>
                                    <input value={question} name="question" type="text" placeholder="Enter Question" className="formInput" />
                                    <span style={{ color: "red" }}>
                                        {error?.questionEmpty}
                                    </span>
                                </li>

                                <li>
                                    <input value={answer} name="answer" type="text" placeholder="Enter Answer" className="formInput" />
                                    <span style={{ color: "red" }}>
                                        {error?.answerEmpty}
                                    </span>
                                </li>

                                <li>
                                    <Button disabled={isloading} className="loginBtn" type="submit">{isloading ? "Loading..." : "Edit"}</Button>
                                </li>
                            </ul>
                        </form>
                    </div>
                )}

                {modalStep === 2 && (
                    <div className="popupBox">
                        <a className="closePopup" onClick={handleClose}>
                            <span className="material-icons">cancel</span>
                        </a>
                        <div className="tac sucessfull">
                            <span className="material-icons-outlined">verified</span>
                        </div>
                        <div className="popupHd tac">FAQ Edit Successfully!</div>
                        {/* <Button className="loginBtn" onClick={handleClose}>Close</Button> */}
                    </div>
                )}
            </div>
        </>
    );
};

export default EditFAQ;
