import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { sendPaymentInterface } from "../../../services/interface/bank.interface"
import walletService from "../../../services/walletService"
import PopUp from "../../../component/PopUp";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from "react-loading-skeleton";
import Form from 'react-bootstrap/Form';

interface UpdateProps {
  onClose: () => void;
  setWalletLoader: Dispatch<SetStateAction<boolean>>;
  walletLoader: boolean
}

const initialState: sendPaymentInterface = {
  destination_phone_number: "",
  amount: ""
}

const SendPayment: React.FC<UpdateProps> = ({ onClose, setWalletLoader, walletLoader }) => {
  const [modalStep, setModalStep] = React.useState<number>(1);
  const [iState, updateState] = useState<sendPaymentInterface>(initialState)
  const [isloading, setIsloading] = useState<boolean>(false)
  const [popup, setPopup] = useState({ show: false, message: "" })
  const [error, setError] = useState<any>({})
  const [selectedCard, setSelectedCard] = useState<string>("")

  const { amount, destination_phone_number } = iState

  const cardData = useSelector((state: any) => state.card)

  useEffect(() => {
    cardData?.data?.map((data: any) => {
      setSelectedCard(data.id)
    })
  }, [])

  const handleClose = () => {
    onClose();
  };

  const changeBankAccountForm = (e: any) => {
    const { value, name } = e.target
    const regex = /^\d+$/;
    const amountRegex = /^$|^[1-9]\d*(\.\d{1,2})?$/;

    if (name === "destination_phone_number" && regex.test(value)) {
      updateState({
        ...iState,
        [name]: value
      })
    }

    if (name === "amount" && amountRegex.test(value)) {
      updateState({
        ...iState,
        [name]: value
      })
    }


  }

  // handle form validation
  let handleValidation = () => {
    let amountEmpty
    let formIsValid = true;

    if (!amount.trim()) {
      amountEmpty = "Please enter amount.";
      formIsValid = false;
    }

    setError({
      amountEmpty,
      formIsValid,
    });
    return formIsValid;
  };

  const handleSendMoney = (e: any) => {
    e.preventDefault()
    const isValid = handleValidation()
    if (!isValid) {
      return
    }

    setModalStep(2)

    // setIsloading(true)

    // const payload = {
    //   destination_phone_number,
    //   amount: Number(amount)
    // }

    // walletService.sendMerchantMoney(payload).then((res) => {
    //   setIsloading(false)
    //   if (res.status === "success") {
    //     setWalletLoader(!walletLoader)
    //     setModalStep(2)
    //   }
    // }).catch((err) => {
    //   setPopup({ show: true, message: err?.response?.data?.message })
    //   setIsloading(false)
    // })
  }

  const handleWithdrawMoneyToBank = (e: any) => {
    e.preventDefault()
    setIsloading(true)
    const payload = {
      amount: parseInt(amount),
      // payment_method_id: selectedCard,
      // customer_id: localStorage.getItem("customerId")
    }

    walletService.withdrawFundIntoMerchantBank(payload).then((res) => {
      setIsloading(false)
      if (res.status === "success") {
        setWalletLoader(!walletLoader)
        // setModalStep(3)
      }
    }).catch((err) => {
      setPopup({ show: true, message: err?.response?.data?.message })
      setIsloading(false)
    })


  }

  const handleSelectCard = (id: string) => {
    setSelectedCard(id)
  }

  return (
    <>
      <div>
        {modalStep === 1 && (
          <div className="popupBox">
            <a className="closePopup" onClick={handleClose}>
              <span className="material-icons">cancel</span>
            </a>
            <div className="popupHd tac">Withdraw Money To Bank</div>
            <div className="tac m-3">Send money to your default account</div>
            <form onSubmit={handleWithdrawMoneyToBank} onChange={changeBankAccountForm} >
              <ul className="loginForm m-0 p-0">
                <li>
                  <input value={amount} name="amount" type="text" placeholder="Enter Amount" className="formInput" />
                  <span style={{ color: "red" }}>
                    {error?.amountEmpty}
                  </span>
                </li>

                <li>
                  <Button disabled={isloading} className="loginBtn" type="submit">{isloading ? "Loading..." : "Proceed Securely"}</Button>
                </li>
              </ul>
            </form>
          </div>
        )}

      </div>
      <Modal show={popup.show}><PopUp message={popup.message} onClose={() => setPopup({ show: false, message: "" })} /></Modal>
    </>
  );
};

export default SendPayment;
