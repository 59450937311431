import React, { useState, Dispatch, SetStateAction } from 'react';
import Button from 'react-bootstrap/Button';
import { sendPaymentInterface } from "../../services/interface/bank.interface"
import walletService from "../../services/walletService"
import identityService from "../../services/identityService"
import supportService from "../../services/supportService"
import PopUp from "../../component/PopUp";
import Modal from "react-bootstrap/Modal";

interface UpdateProps {
    onClose: () => void;
    setFaqLoader: Dispatch<SetStateAction<boolean>>;
    faqLoader: boolean
}

const initialState: any = {
    title: "",
    question: "",
    answer: ""
}

const AddFAQTitle: React.FC<UpdateProps> = ({ onClose, setFaqLoader, faqLoader }) => {
    const [modalStep, setModalStep] = React.useState<number>(1);
    const [iState, updateState] = useState<any>(initialState)
    const [isloading, setIsloading] = useState<boolean>(false)
    const [popup, setPopup] = useState({ show: false, message: "" })
    const [error, setError] = useState<any>({})

    const { question, title, answer } = iState

    const handleClose = () => {
        onClose();
    };

    const changeBankAccountForm = (e: any) => {
        const { value, name } = e.target
        updateState({
            ...iState,
            [name]: value
        })
    }

    // handle form validation
    let handleValidation = () => {
        let titleEmpty
        let questionEmpty
        let answerEmpty
        let formIsValid = true;

        if (!title?.trim()) {
            titleEmpty = "Please enter title.";
            formIsValid = false;
        }

        if (!question?.trim()) {
            questionEmpty = "Please enter question.";
            formIsValid = false;
        }

        if (!answer?.trim()) {
            answerEmpty = "Please enter answer.";
            formIsValid = false;
        }

        setError({
            titleEmpty,
            questionEmpty,
            answerEmpty,
            formIsValid,
        });
        return formIsValid;
    };

    const handleAddFAQ = (e: any) => {
        e.preventDefault()
        const isValid = handleValidation()
        if (!isValid) {
            return
        }

        setIsloading(true)
        supportService.AddFAQ(iState).then((res: any) => {
            setIsloading(false)
            setFaqLoader(!faqLoader)
            setModalStep(2)
        }).catch((err) => {
            setIsloading(false)
            setPopup({ show: true, message: err?.response?.data?.message })
        })


    }

    return (
        <>
            <div>
                {modalStep === 1 && (
                    <div className="popupBox">
                        <a className="closePopup" onClick={handleClose}>
                            <span className="material-icons">cancel</span>
                        </a>
                        <div className="popupHd tac">Create FAQ Title</div>
                        <form onSubmit={handleAddFAQ} onChange={changeBankAccountForm} >
                            <ul className="loginForm m-0 p-0">
                                <li>
                                    <input value={title} name="title" type="text" placeholder="Enter Title" className="formInput" />
                                    <span style={{ color: "red" }}>
                                        {error?.titleEmpty}
                                    </span>
                                </li>
                                <li>
                                    <input value={question} name="question" type="text" placeholder="Enter Question" className="formInput" />
                                    <span style={{ color: "red" }}>
                                        {error?.questionEmpty}
                                    </span>
                                </li>

                                <li>
                                    <input value={answer} name="answer" type="text" placeholder="Enter Answer" className="formInput" />
                                    <span style={{ color: "red" }}>
                                        {error?.answerEmpty}
                                    </span>
                                </li>

                                <li>
                                    <Button disabled={isloading} className="loginBtn" type="submit">{isloading ? "Loading..." : "Add"}</Button>
                                </li>
                            </ul>
                        </form>
                    </div>
                )}

                {modalStep === 2 && (
                    <div className="popupBox">
                        <a className="closePopup" onClick={handleClose}>
                            <span className="material-icons">cancel</span>
                        </a>
                        <div className="tac sucessfull">
                            <span className="material-icons-outlined">verified</span>
                        </div>
                        <div className="popupHd tac">FAQ Added Successfully!</div>
                        {/* <Button className="loginBtn" onClick={handleClose}>Close</Button> */}
                    </div>
                )}
            </div>
            <Modal show={popup.show}><PopUp message={popup.message} onClose={() => setPopup({ show: false, message: "" })} /></Modal>
        </>
    );
};

export default AddFAQTitle;
