import { useEffect, useState } from 'react'
import Receipt from '../../../component/Receipt';
import DatePicker from "react-datepicker";
import Modal from 'react-bootstrap/Modal';
import "react-datepicker/dist/react-datepicker.css";
import { getAllTickets } from '../../../redux/features/dispute/disputeAction';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { convertUTCDateTimetoLocal } from '../../../utils/timeConverter';
import Skeleton from 'react-loading-skeleton';
import ReactPaginate from 'react-paginate';
import DocumentPreview from '../../../component/DocumentPreview';





const AdminDispute = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const dispatch: AppDispatch = useDispatch()
    const [isActive, setIsActive] = useState(false);
    const toggleMenu = () => {
        setIsActive(!isActive);
    };
    const disputeData = useSelector((state: any) => state.dispute)
    const [singleDate, setSingleDate] = useState<Date | null>(new Date());
    const [perPage, setPerPage] = useState(10)
    const [paramState, setParamState] = useState({ skip: 0, limit: 10 });
    const { skip, limit } = paramState;
    useEffect(() => {
        const getTicketData = async () => {

            const data = {
                skip,
                limit
            }
            const res = await dispatch(getAllTickets(data))
        }

        getTicketData()
    }, [])

    const handlePageChange = async ({ selected }: any) => {
        let selectedPage = selected + 1;
        setPerPage(10 * Number(selectedPage));
        let params = { skip: selectedPage, limit: perPage };
        await dispatch(getAllTickets(params));
        setParamState({
            skip: selectedPage,
            limit: perPage
        })
    };


    return (
        <>
            <div className="dbRight">
                <div className="mainHd">Disputes</div>
                <div className="whtBox">
                    <div className="flexBox itemCenter justifyContentBetween filterRow">
                        <div className="totalNumber">Total: {disputeData.data.length}</div>
                        <ul className="flexBox filter transactionsFilter">
                            {/* <li>
                                <div className="filterSearch pr"><input type="text" placeholder="Search by Name or ID"
                                    className="filterInput" /> <span className="material-icons icon">search</span></div>
                            </li> */}
                            {/* <li>
                                <div className="filterDate pr"><DatePicker className='filterInput datepicker' selected={singleDate} onChange={(date) => setSingleDate(date)} /> <span
                                    className="material-icons icon">calendar_month</span></div>
                            </li> */}
                            {/* <li>
                            <select className="filterSelect">
                                <option>Payment Source</option>
                            </select>
                        </li> */}
                            {/* <li>
                                <select className="filterSelect">
                                    <option>Status</option>
                                </select>
                            </li> */}
                        </ul>
                    </div>
                    <div className="dbTable">
                        <table>
                            <tr>
                                <th>Sr. No.</th>
                                <th>User</th>
                                <th>Trx ID.</th>
                                <th>Dispute Type</th>
                                <th>Date & Time</th>
                                <th>Amount</th>
                                <th>Dispute Status</th>
                                <th>File</th>
                                {/* <th>Action</th> */}
                            </tr>

                            {disputeData?.loading ?
                                [...Array(10)].map((data, i) => {
                                    return (
                                        <>
                                            <tr key={i}>
                                                <td><Skeleton /></td>
                                                <td><Skeleton /></td>
                                                <td><Skeleton /></td>
                                                <td><Skeleton /></td>
                                                <td><Skeleton /></td>
                                                <td><Skeleton /></td>
                                                <td><Skeleton /></td>
                                                <td><Skeleton /></td>
                                                {/* <td><Skeleton /></td> */}
                                            </tr>
                                        </>
                                    )
                                }) : ""}

                            {disputeData?.data.map((data: any, i: any) => {
                                return (
                                    <tr>
                                        <td>{i + 1 + (perPage - 10)}</td>
                                        <td>{data?.ew_user_name}</td>
                                        <td>{data?.ew_user_id}</td>
                                        <td>{data?.ew_ticket_category}</td>
                                        <td>{convertUTCDateTimetoLocal(data?.created_at)}</td>
                                        <td>{data?.ew_amount ? `$${data?.ew_amount}` : ""}</td>
                                        <td>
                                            <span className={`status ${data?.ew_ticket_status?.toLowerCase()}`}>{data?.ew_ticket_status}
                                            </span>
                                        </td>
                                        <td>
                                        <DocumentPreview preview={data?.ew_attachment_url} />
                                        </td>
                                        {/* <td><a className="viewLink">View Receipt</a></td> */}
                                    </tr>
                                )
                            })}
                        </table>
                    </div>
                </div>
                <div className="flexBox itemCenter justifyContentBetween">
                    <ReactPaginate
                        pageCount={Math.ceil(
                            disputeData?.data?.total / 10
                        )}
                        onPageChange={handlePageChange}
                        containerClassName={"paging m-0 p-0 pt-3 d-flex"}
                        activeClassName={"active"}
                        previousLabel={"<"}
                        nextLabel={">"}
                    />
                </div>
            </div>
            <Modal show={show}>
                <Receipt onClose={handleClose} />
            </Modal>
        </>

    )
}

export default AdminDispute;
